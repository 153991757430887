/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { JsonLd } from "./JsonLd"

// TODO put back OSANA when SEO works

function SEO({ description = "", lang, meta, title = "" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  )

  const _description = description || site.siteMetadata.description
  const _title = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={_title}
      description={_description}
      meta={[
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          name: `description`,
          content: _description,
        },
        {
          property: `og:title`,
          content: _title,
        },
        {
          property: `og:description`,
          content: _description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: _title,
        },
        {
          name: `twitter:description`,
          content: _description,
        },
      ].concat(meta)}
    >
      <JsonLd>
        {{
          "@context": "https://schema.org",
          "@type": "Hospital",
          url: "https://cliniquebelleetoile.fr",
          name: "Clinique ophtalmologique - Belle Etoile",
          medicalSpecialty: "Centre de santé",
          address: {
            "@type": "PostalAddress",
            name: "Clinique ophtalmologique - Belle Etoile",
            streetAddress: "74 Rue de la Belle Étoile",
            postalCode: "95700",
            addressLocality: "Roissy-en-France",
          },
          paymentAccepted: "Cash, Check, Credit card",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "01 49 38 00 78",
            contactType: "Accueil",
          },
        }}
      </JsonLd>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
